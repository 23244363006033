.yubi_screen_container{
    height: 100vh;
    .yubi_container{
        height: 80vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 20px;
        .error_text_yubi{
            font-size: 20px;
            font-weight: 600;
        }
        .back_btn_yubi{
            padding: 9px 16px;
            font-size: 18px;
            font-weight: 500;
            color: white;
            background: #000066;
            border-radius: 13px;
        }
    }
}