@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import "../../../assets/css/color.scss";
@import '../../../assets/css/MuiMediaQueries.scss';

header {
    &.header {
        padding: 30px 0;
        position: relative;
        z-index: 999;
    }
   
     .container {
        max-width: 1500px;
        @include xl-down {
            max-width: 1300px;

        }
        @include lg-down {
            max-width: 95%;
        }
        @include sm-down {
            max-width: 100%;
            padding-left: 16px !important;
            padding-right: 16px !important ;
        }
    }
}

.navigation {
    ul {
        margin: 0;
        padding: 0;
        display: flex;
        justify-content: center;
        li {
            display: inline-block;
            a {
                color: #000066;
                font-family: "Montserrat", sans-serif;
                font-size: 16px;
                font-weight: 700;
                padding: 0 11px;
                text-decoration: none;
                white-space: nowrap;
            }
        }
    }
}
.flex-row {
    display: flex;
}
.alignCenter {
    align-items: center;
}
.getStart{
    background: rgba(238, 0, 37, 1);
    border-radius: 30px;
    color: white;
    font-weight: 700;
    font-size: 17px;
}
.qrDropDown {
    background: #ee0025;
    padding: 25px 20px;
    border-radius: 30px;
    display: none;
    position: absolute;
    right: 0;
    width: 165%;
    top: 70px;
    // display: flex;
    gap: 12px;
    justify-content: space-between;
    @include lg-down {
        width:250px
    }
}
.qrDropDown .QR_text p {
    font-size: 20px;
    color: #ffffff;
    font-weight: 700;
    margin: 0;
    text-align: left;
    @include xl-down {
        font-size: 16px;
        padding-bottom: 10px;
    }
}
.qrDropDown .imageCode img{
    height: 7em;
    width:208px;
}
.qrDropDown .QR_text{
    display: flex;
    flex-direction: column;
}
.qrDropDown .QR_text .imageIcon {
    color: #ffffff;
    font-size: 22px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    .icon_name_div{
        display: flex;
        gap: 5px;
        align-items: center;
        .store_icon{
            height: 1em;
        }
        .store_name{
            font-size: 15px;
        }
    }
}
.headerBtn button:hover .qrDropDown {
    display: flex;
    gap: 20px;
}
.hdrRight {
    justify-content: space-around;
}
.logo {
    img {
        width: 145px;
        margin-top: -12px;
    }
}
.dotsIcon {
    cursor: pointer;
    svg {
        font-size: 42px;
        color:$color-blue-dark;
        cursor: pointer;
        @include xl-down {
            font-size: 32px;
        }
        @include xl-down {
            font-size: 28px;
        }
    }
}
#dropdown-basic-menu {
    .MuiPaper-root {
        background: rgba(240, 240, 252, 0.65);
        box-shadow: 14px 34px 40px rgba(158, 158, 158, 0.4);
        -webkit-backdrop-filter: blur(20px);
        backdrop-filter: blur(20px);
        width: 489px;
        border-radius: 31px;
        overflow: inherit;
        margin-top: 40px;
        .MuiList-root {
            padding: 30px;
            .MuiMenuItem-root {
                padding: 0;
                font-weight: 500;
                font-size: 18px;
                line-height: 27px;
                border-bottom: 2px solid rgba(0, 0, 0, 0.15);
                transition: 0.3s;
                color: $color-blue-dark;
                z-index: 1;
                a {
                    font-weight: 500;
                    font-size: 18px;
                    line-height: 20px;
                    text-decoration: none;
                    color: $color-blue-dark;
                    padding: 23px 15px;
                    display: block;
                    width: 100%;
                }
                &::before {
                    opacity: 0;
                    visibility: hidden;
                    content: "";
                    background-color: $menuHover;
                    height: 100%;
                    position: absolute;
                    transform: scale(1);
                    right: 0;
                    left: 0px;
                    top: 0;
                    z-index: -1;
                    border: 0.5px solid #b5b5f0;
                    box-shadow: 0px 7px 9px rgb(0 0 0 / 25%);
                    border-radius: 7px;
                    transition: 0.3s;
                }
                &:last-child {
                    border: none;
                }
                &:hover {
                    color: $menuHoverText;
                    font-weight: 800;
                    border-bottom-color: transparent;
                    background-color: transparent;
                    &::before {
                        opacity: 1;
                        visibility: visible;
                        transform: scale(1.25, 1.1);
                    }
                    a {
                        color: $menuHoverText;
                        font-weight: 800;
                        z-index: 9;
                        margin-left: -50px;
                        margin-right: -50px;
                        display: block;
                        width: 160%;
                        padding-left: 60px;
                        padding-right: 60px;
                        @include md-down {
                            margin-left: -30px;
                            margin-right: -50px;
                            padding-left: 44px;
                            padding-right: 60px;
                        }
                    }
                }
                &.mobileMenu {
                    display: none;
                }
            }
        }
    }
    .css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root.Mui-focusVisible {
        background-color: transparent !important;
    }
}

@media (max-width: 1525px) {
    .headerBtn {
        button {
            padding: 16px 45px;
        }
    }
    #dropdown-basic-menu {
        .MuiPaper-root {
            width: 400px;
        }
    }
    .navigation {
        ul {
            li {
                a {
                    font-size: 14px;
                    padding: 0 9px;
                }
            }
        }
    }
}
@include lg-deskop {
        .navigation {
        ul {
            li {
                a {
                    font-size: 13px;
                }
            }
        }
    }

}
@media (max-width: 1199px) {
    .navigation ul li a {
        padding: 0 8px;
        font-size: 12px;
    }
    .headerBtn button {
        padding: 12px 16px;
        font-size: 13px;
    }
}
@media (max-width: 1023px) {
    .headerBtn button {
        padding: 10px 15px;
        font-size: 12px;
    }

    #dropdown-basic-menu {
        .MuiPaper-root {
            width: 245px;
            border: none !important;
            .MuiList-root {
                padding: 15px;
                .MuiMenuItem-root {
                   
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 20px;
                    &:hover {
                        font-size: 15px;
                    }
                    &:hover::before {
                        -webkit-transform: scale(1.3, 1.1);
                        -ms-transform: scale(1.3, 1.1);
                        transform: scale(1.3, 1.1);
                    }
                    a {
                        font-size: 14px;
                        padding: 15px;
                    }
                }
            }
        }
    }
}
@media (max-width: 991px) {
    header {
        .logo {
            img {
                width: 150px;
                margin-top: 0;
            }
        }
        .hdrMenu {
            display: none;
        }
        .headerBtn {
            display: none;
        }
    }

    .hdrRight {
        justify-content: end;
    }
    #dropdown-basic-menu {
        .MuiPaper-root {
            .MuiList-root {
                .MuiMenuItem-root {
                    &.mobileMenu {
                        display: block;
                    }
                    &:nth-child(3) {
                        border-bottom: 1px solid rgba(0, 0, 0, 0.15);
                    }
                }
            }
        }
    }
}
@media (max-width: 767px) {
    header .col-md-2 {
        text-align: left;
    }

    .logo {
        img {
            width: 250px;
        }
    }
    header {
        &.header {
            padding: 20px 4px;
        }
    }
    #dropdown-basic-menu {
        .MuiPaper-root {
            width: 227px;
            border: none !important;
            border-radius: 15px;
            margin-top: 15px;
            background: rgba(240, 240, 252, 0.25);
            box-shadow: 0px 0px 40px rgba(158, 158, 158, 0.6);

            .MuiList-root {
                border-radius: 15px;
                .MuiMenuItem-root {
                    border-bottom-width: 1px;
                    white-space: normal;
                    font-size: 14px;
                    &.Mui-focusVisible {
                        background-color: transparent !important;
                        -webkit-tap-highlight-color: transparent !important;
                    }
                }
            }
        }
    }
    .homeBanner {
        .bannerText {
            h1 {
                font-size: 21px;
                line-height: 1.5;
                padding-right: 0;
                span {
                    font-size: 20px;
                }
            }
        }
    }
}
