.newHomePage_container{
    padding: 10px;
    .top_carousal_bannner_container{
        margin-top: 3em;
        padding: 0px 8em 0px 5em;
        .carousel-content{
            display: flex;
            .left_carousal_content_div{
                display: flex;
                flex-direction: column;
                gap: 20px;
                width: 72%;
                .best_credit_line_text{
                    border-radius: 20px;
                    background: rgba(0, 0, 102, 0.05);
                    color: #006;
                    font-family: Montserrat;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: normal;
                    width: fit-content;
                    padding: 12px 20px;
                    padding: 10px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
                .heading_div{
                    display: flex;
                    flex-direction: column;
                    gap: 10px;
                    .heading{
                        font-family: Montserrat;
                        font-size: 54px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: normal;
                    }
                    .blue_heading{
                        color: #006;
                    }
                    .red_heading{
                        color: #EE0025;
                    }
                }
                .sub_heading{
                    color: rgba(0, 0, 0, 0.80);
                    font-family: Montserrat;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 500;
                    width: 65%;
                }
                .apply_now_btn{
                    border-radius: 28px;
                    background: #EE0025;
                    color: #FFF;
                    font-family: Montserrat;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: normal;
                    width: 22%;
                    padding: 12px;
                }
                .tick_div{
                    display: flex;
                    gap: 20px;
                    .tick_option{
                        display: flex;
                        gap: 5px;
                        .tick_red{
                            height: 12px;
                        }
                        .option{
                            color: #000;
                            font-family: Montserrat;
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 600;
                            line-height: normal;
                        }
                    }
                }
            }
            .right_image_div{
                overflow: visible;
                display: flex;
                justify-content: center;
                .image0{
                    width: 18em;
                    height: 29em;
                    flex-shrink: 0;
                }
                .image1{
                    width: 562.205px;
                    height: 420px;
                    flex-shrink: 0;
                    margin-top: 20px;
                }       
                .image2{
                    width: 566.445px;
                    height: 360px;
                    flex-shrink: 0;
                    margin-top: 20px;
                }
                .image3{
                    width: 483.606px;
                    height: 348.076px;
                    flex-shrink: 0;
                    margin-top: 20px;
                }
                
            }
        }
    }
    .ourProductsNBenefits_container{
        border-radius: 48px;
        background: linear-gradient(180deg, rgba(0, 0, 102, 0.05) 0%, rgba(249, 249, 249, 0.05) 100%);
        padding: 3em 5em;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 40px;
        .ourProducts_heading{
            color: #006;
            font-size: 36px;
            font-weight: 700;
            text-align: center;
            line-height: 0.5;
            .ourProducts_subHeading{
                color: #000;
                font-size: 16px;
                font-weight: 500;
            }
        }
        .ourProducts_cards{
            display: flex;
            justify-content: space-between;
            .product_card{
                display: flex;
                flex-direction: column;
                padding: 16px 20px;
                border-radius: 12px;
                border: 1px solid rgba(0, 0, 102, 0.05);
                background: #FFF;
                gap : 20px ;
                justify-content: space-between;
                width: 20%;
                .product_content{
                    display: flex;
                    flex-direction: column;
                    gap: 16px;
                    .product_icon{
                        width: 66px;
                        height: 62px;
                        flex-shrink: 0;
                    }
                    .product_name{
                        color: #000;
                        font-family: Montserrat;
                        font-size: 20px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: normal;
                    }
                    .product_desc{
                        color: rgba(0, 0, 0, 0.80);
                        font-size: 13px;
                        font-weight: 500;
                    }
                }
                .discover_text{
                    color: #21213A;
                    font-family: Montserrat;
                    font-size: 15px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: normal;
                    display: flex;
                    gap: 5px;
                    align-items: center;
                    cursor: pointer;
                    .arrowBlackRight{
                        width: 16px;
                        height: 16px;
                        flex-shrink: 0;
                    }
                }
            }
        }
        .benefits_heading{
            color: #006;
            text-align: center;
            font-size: 36px;
            font-weight: 700;
        }
        .benefits_items{
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            gap: 20px;
            .benefit_item{
                width: 32%;
                display: flex;
                flex-direction: column;
                gap : 5px;
                align-items: center;
                margin-top: 30px;
                .benefit_head{
                    color: #000;
                    font-size: 16px;
                    font-weight: 700;
                    text-align: center;
                }
                .benefit_desc{
                    color: rgba(0, 0, 0, 0.60);
                    text-align: center;
                    font-size: 13px;
                    font-weight: 500;
                    text-align: center;
                }
            }
        }
        .apply_now_btn{
            border-radius: 28px;
            background: #EE0025;
            color: #FFF;
            font-size: 16px;
            font-weight: 700;
            padding: 12px 30px;
        }
        .stores_div{
            display: flex;
            gap: 5px;
            justify-content: center;
            .store_img{
                width: 168.911px;
                height: 48px;
                flex-shrink: 0;
            }
        }
    }
    .apply_know_divs{
        padding: 0px 8em 0px 5em;
        display: flex;
        flex-direction: column;
        gap: 60px;
        .apply_div{
            border-radius: 16px;
            background: rgba(0, 0, 102, 0.05);
            padding: 29px;
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 37px;
            .heading_sub_div{
                display: flex;
                flex-direction: column;
                gap: 12px;
                align-items: center;
                .heading{
                    color: #006;
                    text-align: center;
                    font-size: 28px;
                    font-weight: 700;
                }
                .subheading{
                    color: #000;
                    font-size: 14px;
                    font-weight: 500;
                    text-align: center;
                }
            }
            .steps_div{
                display: flex;
                gap: 10px;
                align-items: center;
                .number_text{
                    display: flex;
                    flex-direction: column;
                    gap: 5px;
                    align-items: center;
                    width: 8em;
                    .number{
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border-radius: 50%;
                        background: #000066;
                        color: white;
                        font-size: 17px;
                        font-weight: 500;
                        padding: 5px 15px;
                    }
                    .step_text{
                        color: #000;
                        text-align: center;
                        font-size: 12px;
                        font-weight: 600;
                    }
                }
                .horizontal_divider{
                    width: 10em;
                    height: 2px;
                    transition: transform 0.3s ease;
                }
            }
            .apply_now_btn{
                border-radius: 28px;
                background: #EE0025;
                color: #FFF;
                font-family: Montserrat;
                font-size: 16px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                padding: 12px 30px;
            }
        }
        .know_credit_div{
            display: flex;
            padding: 20px 40px;
            border-radius: 16px;
            border: 1px solid rgba(0, 0, 102, 0.80);
            background: #FFF;
            justify-content: space-between;
            .know_left_content{
                display: flex;
                flex-direction: column;
                gap: 20px;
                .know_heading{
                    color: rgba(0, 0, 102, 0.80);
                    font-size: 24px;
                    font-weight: 600;
                    text-align: center;
                }
                .know_desc{
                    color: #000;
                    font-size: 12px;
                    font-weight: 500;
                    text-align: center;
                }
                .know_btn{
                    border-radius: 8px;
                    border: 1px solid #000;
                    background: #FFF;
                    color: #000;
                    font-size: 14px;
                    font-weight: 600;
                    padding: 10px;
                    width: 14em;
                }
            }
            .chart{
                width: 297.998px;
                height: 160px;
                flex-shrink: 0;
            }       
        }
    }
    .smiling_faces_div{
        background: #F1F1F1;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 34px;
        margin-top: 50px;
        padding: 20px;
        .smiling_heading{
            color: #000;
            font-size: 22px;
            font-weight: 700;
            text-align: center;
            .smiling_desc{
                color: #000;
                font-size: 15px;
                font-weight: 500;
            }
        }
        .users_count_div{
            display: flex;
            width: 70%;
            justify-content: space-between;
            .happy_count{
                display: flex;
                flex-direction: column;
                align-items: center;
                .count{
                    color: #000;
                    font-family: Montserrat;
                    font-size: 24px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: normal;
                }
                .label{
                    color: #000;
                    font-family: Montserrat;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                }
            }
        }
        .stores_div{
            display: flex;
            gap: 5px;
            justify-content: center;
            .store_img{
                width: 168.911px;
                height: 48px;
                flex-shrink: 0;
            }
        }
    }
    .testimonial_div{
        background: #006;
        color: white;
        padding: 26px 5em;
        display: flex;
        flex-direction: column;
        gap: 38px;
        margin-top: 40px;
        .testi_heading_desc_div{
            display: flex;
            flex-direction: column;
            align-items: center;
            .testi_heading{
                color: #FFF;
                text-align: center;
                font-size: 27px;
                font-weight: 700;
            }
            .testi_desc{
                color: #FFF;
                text-align: center;
                font-size: 16px;
                font-weight: 500;
            }
        }
        .testi_cards_div{
            display: flex;
            justify-content: space-between;
            .testi_card{
                border-radius: 24px;
                background: #FFF;
                display: flex;
                flex-direction: column;
                color: black;
                padding: 20px;
                gap: 20px;
                width: 32%;
                justify-content: space-between;
                .stars{
                    height: 20px;
                    width: 117px;
                }
                .testi_review{
                    color: #000;
                    font-size: 13px;
                    font-weight: 500;
                }
                .person_info_div{
                    display: flex;
                    align-items: center;
                    gap: 20px;
                    .person{
                        height: 47px;
                    }
                    .reviewer_details{
                        display: flex;
                        flex-direction: column;
                        .reviewer_name{
                            color: #000;
                            font-size: 16px;
                            font-weight: 700;
                        }
                        .review_date{
                            color: rgba(0, 0, 0, 0.40);
                            font-size: 14px;
                            font-weight: 500;
                        }
                    }
                }
            }
        }
    }
    .last_inwards_div{
        padding: 0px 5em;
        .ready_div{
            border-radius: 16px;
            background: rgba(0, 0, 102, 0.05);
            margin-top: 13em;
            padding: 38px 38px 0px 38px;
            display: flex;
            justify-content: space-between;
            .left_ready_div{
                display: flex;
                flex-direction: column;
                gap: 20px;
                .ready_heading{
                    color: #006;
                    font-size: 28px;
                    font-weight: 700;
                }
                .ready_desc{
                    color: #000;
                    font-size: 16px;
                    font-weight: 500;
                }
                .stores_div{
                    display: flex;
                    gap: 5px;
                    .store_img{
                        width: 168.911px;
                        height: 48px;
                        flex-shrink: 0;
                    }
                }
            }
            .right_ready_img{
                width: 255px;
                height: 468px;
                flex-shrink: 0;
                margin-top: -12em;
                margin-right: 20px;
            }
        }
        .home_blog_div{
            display: flex;
            flex-direction: column;
            gap: 20px;
            margin-top: 40px;
            .blog_head_desc_div{
                display: flex;
                flex-direction: column;
                gap: 10px;
                align-items: center;
                .blogs_heading{
                    color: #006;
                    text-align: center;
                    font-size: 31px;
                    font-weight: 700;
                }
                .blogs_desc{
                    color: #000;
                    text-align: center;
                    font-size: 15px;
                    font-weight: 500;
                }
            }
            .blogs_cards_div{
                display: flex;
                justify-content: space-between;
                margin-top: 40px;
                .blog_card{
                    display: flex;
                    flex-direction: column;
                    width: 21%;
                    gap: 15px;
                    .blog_image{

                    }
                    .blog_heading{
                        color: #000;
                        font-size: 16px;
                        font-weight: 600;
                    }
                    .blog_desc{
                        color: rgba(0, 0, 0, 0.80);
                        font-size: 12px;
                        font-weight: 500;
                        .readMoreHome{
                            color: #6BA7FF;
                            text-decoration: underline;
                            cursor: pointer;
                        }
                    }
                }
            }
        }
        .FAQ_Section{
            display: flex;
            flex-direction: column;
            gap: 30px;
            margin-top: 40px;
            .haveQues_div{      
                border-radius: 16px;
                background: #006;
                display: flex;
                padding: 20px;
                padding-right: 5em;
                padding-bottom: 0px;
                justify-content: space-between;
                margin-bottom: 40px;
                .haveQ_left_content_div{
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    gap: 20px;
                    .haveQ_haeding{
                        color: #FFF;
                        font-size: 32px;
                        font-weight: 600;
                    }
                    .haveQ_desc{
                        color: #FFF;
                        font-size: 16px;
                        font-weight: 500;
                    }
                    .contact_btn{
                        border-radius: 8px;
                        background: #FFF;
                        padding: 9px 20px;
                    }
                }
                .faq_person_img{
                    height: 13em;
                }
            }
            .faq_heading_desc_div{
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: 10px;
                .faq_heading{
                    color: #006;
                    text-align: center;
                    font-size: 36px;
                    font-weight: 700;
                }
                .faq_desc{
                    color: #000;
                    font-size: 16px;
                    font-weight: 500;
                }
            }
            .faqs_section{
                display: flex;
                flex-direction: column;
                gap: 20px;
                .faq_ques_ans_div{
                    display: flex;
                    flex-direction: column;
                    gap: 20px;
                    padding: 10px;
                    border-radius: 8px;
                    border: 1px solid rgba(0, 0, 0, 0.10);
                    padding-left: 20px;
                    .faq_ques_div{
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        .faq_ques{
                            color: #000;
                            text-align: center;
                            font-size: 16px;
                            font-weight: 600;
                        }
                        .down_vector_faq{
                            height: 10px;
                            cursor: pointer;
                            transition: transform 0.3s ease;
                        }
                        .up{
                            transform: rotate(180deg);
                        }
                    }
                    .faq_ans{
                        color: rgba(0, 0, 0, 0.60);
                        font-size: 16px;
                        font-weight: 500;
                    }
                }
            }
        }
    }
}
@media (max-width : "1370px") {
    .newHomePage_container .top_carousal_bannner_container .carousel-content .left_carousal_content_div .heading_div .heading {
        font-size: 48px;
    }
    .newHomePage_container .ourProductsNBenefits_container .ourProducts_cards .product_card {
        width: 23%;
    }
}
@media (max-width : "1290px") {
    .newHomePage_container .top_carousal_bannner_container {
        margin-top: 3em;
        padding: 0px 4em 0px 5em;
    }
    .newHomePage_container .apply_know_divs {
        padding: 0px 1em 0px 1em;
    }
}
@media (max-width : "1214px") {
    .newHomePage_container .top_carousal_bannner_container .carousel-content .left_carousal_content_div .heading_div .heading {
        font-size: 45px;
    }
    .newHomePage_container .top_carousal_bannner_container .carousel-content .left_carousal_content_div .sub_heading {
        font-size: 15px;
    }
}
@media (max-width : "1168px") {
    .newHomePage_container .top_carousal_bannner_container .carousel-content .left_carousal_content_div .heading_div .heading {
        font-size: 35px;
    }
    .newHomePage_container .ourProductsNBenefits_container .ourProducts_cards .product_card {
        width: 24%;
    }
    .newHomePage_container .testimonial_div {
        padding: 26px 1em;
    }
}
@media (max-width : "1009px") {
    .newHomePage_container .top_carousal_bannner_container .carousel-content .left_carousal_content_div .heading_div .heading {
        font-size: 32px;
    }
    .newHomePage_container .top_carousal_bannner_container .carousel-content .left_carousal_content_div .sub_heading {
        font-weight: 600;
        font-size: 11px;
    }
    .newHomePage_container .ourProductsNBenefits_container .ourProducts_cards .product_card {
        padding: 10px 10px;
    }
    .newHomePage_container .ourProductsNBenefits_container .ourProducts_cards {
        flex-wrap: wrap;
    }
    .newHomePage_container .ourProductsNBenefits_container .ourProducts_cards .product_card {
        width: 45%;
    }
    .newHomePage_container .ourProductsNBenefits_container .ourProducts_cards {
        gap: 20px;
        justify-content: center;
    }
    .newHomePage_container .ourProductsNBenefits_container {
        padding: 3em 1em;
        margin-top: 20px;
    }
    .newHomePage_container .last_inwards_div {
        padding: 0px 2em;
    }
    .newHomePage_container .last_inwards_div {
        padding: 0px 1em;
    }
}
@media (max-width : "930px") {
    .newHomePage_container .top_carousal_bannner_container .carousel-content .right_image_div {
        display: none;
    }
    .newHomePage_container .top_carousal_bannner_container .carousel-content .left_carousal_content_div .heading_div .heading {
        font-size: 38px;
    }
    .newHomePage_container .top_carousal_bannner_container .carousel-content .left_carousal_content_div {
        width: 85%;
    }
    .newHomePage_container .top_carousal_bannner_container {
        margin-bottom: 46px;
    }
    .newHomePage_container .apply_know_divs .apply_div .steps_div .horizontal_divider {
        width: 4em;
    }
    .newHomePage_container .ourProductsNBenefits_container .benefits_items .benefit_item {
        width: 47%;
    }
    .newHomePage_container .last_inwards_div .ready_div .right_ready_img {
        display: none;
    }
    .newHomePage_container .last_inwards_div .ready_div {
        margin-top: 2em;
    }
    .newHomePage_container .last_inwards_div .ready_div {
        padding: 38px 38px 38px 38px;
    }
    .newHomePage_container .last_inwards_div .ready_div .left_ready_div {
        align-items: center;
    }
    .newHomePage_container .last_inwards_div .ready_div .left_ready_div .ready_desc{
        text-align: center;
    }
    .newHomePage_container .last_inwards_div .ready_div .left_ready_div .ready_heading {
        text-align: center;
    }
    .newHomePage_container .last_inwards_div .home_blog_div .blogs_cards_div .blog_card {
        width: 24%;
    }
    .newHomePage_container .last_inwards_div .home_blog_div .blogs_cards_div {
        margin-top: 18px;
    }
}
@media (max-width : "760px") {
    .newHomePage_container .top_carousal_bannner_container .carousel-content .left_carousal_content_div {
        width: 95%;
    }
    .newHomePage_container .top_carousal_bannner_container .carousel-content .left_carousal_content_div .heading_div .heading {
        font-size: 34px;
    }
    .newHomePage_container .top_carousal_bannner_container .carousel-content .left_carousal_content_div .best_credit_line_text {
        font-size: 13px;
    }
    .newHomePage_container .top_carousal_bannner_container .carousel-content .left_carousal_content_div .apply_now_btn {
        font-size: 13px;
    }
    .newHomePage_container .apply_know_divs .know_credit_div {
        flex-direction: column-reverse;
        padding: 20px 20px;
        align-items: center;
    }
    .newHomePage_container .apply_know_divs .know_credit_div .know_left_content .know_btn {
        width: 100%;
    }
    .newHomePage_container .smiling_faces_div .users_count_div {
        width: 81%;
    }
    .newHomePage_container .testimonial_div .testi_cards_div {
        flex-direction: column;
        gap: 20px;
    }
    .newHomePage_container .testimonial_div .testi_cards_div .testi_card {
        width: 100%;
    }
    .newHomePage_container .last_inwards_div .FAQ_Section .haveQues_div .haveQ_left_content_div .haveQ_haeding {
        font-size: 29px;
    }
    .newHomePage_container .last_inwards_div .FAQ_Section .haveQues_div .haveQ_left_content_div .haveQ_desc {
        font-size: 14px;
    }
    .newHomePage_container .last_inwards_div .FAQ_Section .haveQues_div .faq_person_img{
        display: none;
    }
    .newHomePage_container .last_inwards_div .FAQ_Section .haveQues_div {
        padding-bottom: 20px;
    }   
}
@media (max-width : "646px") {
    .newHomePage_container .top_carousal_bannner_container .carousel-content .left_carousal_content_div .tick_div .tick_option .option {
        font-size: 11px;
    }
    .newHomePage_container .ourProductsNBenefits_container .benefits_heading {
        font-size: 25px;
    }
    .newHomePage_container .ourProductsNBenefits_container .benefits_items {
        flex-direction: column;
        align-items: center;
    }
    .newHomePage_container .ourProductsNBenefits_container .benefits_items .benefit_item {
        width: 100%;
        margin-top: 0px;
    }
    .newHomePage_container .apply_know_divs .apply_div .steps_div {
        flex-direction: column;
    }
    .newHomePage_container .apply_know_divs .apply_div .steps_div .number_text {
        width: 11em;
    }
    .newHomePage_container .apply_know_divs .apply_div .steps_div .horizontal_divider {
        transform: rotate(90deg);
    }
    .newHomePage_container .apply_know_divs .apply_div .steps_div {
        gap: 44px;
    }
    .newHomePage_container .smiling_faces_div .smiling_heading {
        font-size: 20px;
    }
    .newHomePage_container .smiling_faces_div .users_count_div .happy_count .count {
        font-size: 20px;
    }
    .newHomePage_container .smiling_faces_div .users_count_div .happy_count .label {
        font-size: 14px;
    }
    .newHomePage_container .smiling_faces_div .stores_div .store_img {
        width: 152.911px;
        height: 44px;
    }
    .newHomePage_container .smiling_faces_div .stores_div {
        gap: 10px;
    }
    .home_blog_div .blog_head_desc_div .blogs_heading {
        font-size: 27px;
    }
    .home_blog_div .blog_head_desc_div .blogs_desc {
        font-size: 13px;
    }
    .newHomePage_container .last_inwards_div .home_blog_div .blogs_cards_div .blog_card {
        width: 48%;
    }    
    .newHomePage_container .last_inwards_div .home_blog_div .blogs_cards_div {
        flex-wrap: wrap;
        gap: 20px;
    }
    .newHomePage_container .last_inwards_div .home_blog_div .blogs_cards_div .blog_card .blog_image{
        height: 10em;
        width: 15em;
    }
    .newHomePage_container .last_inwards_div .FAQ_Section .faq_heading_desc_div .faq_heading {
        font-size: 28px;
    }
    .newHomePage_container .last_inwards_div .FAQ_Section .faq_heading_desc_div .faq_desc {
        font-size: 13px;
    }
}
@media (max-width : "610px") {
    .newHomePage_container .top_carousal_bannner_container .carousel-content .left_carousal_content_div .heading_div .heading {
        font-size: 31px;
    }
}
@media (max-width : "590px") {
    .newHomePage_container .top_carousal_bannner_container {
        margin-top: 1em;
        padding: 10px 1em 10px 1em;
    }
    .newHomePage_container .top_carousal_bannner_container .carousel-content .left_carousal_content_div {
        gap: 18px;
    }
    .newHomePage_container .top_carousal_bannner_container .carousel-content .left_carousal_content_div .heading_div .heading {
        font-size: 30px;
        font-weight: 700;
        line-height: 28px;
    }
    .newHomePage_container .top_carousal_bannner_container .carousel-content .left_carousal_content_div .apply_now_btn {
        width: 60%;
    }
    .newHomePage_container .top_carousal_bannner_container .carousel-content .left_carousal_content_div .sub_heading {
        font-size: 13px;
    }
    .newHomePage_container .top_carousal_bannner_container .carousel-content .left_carousal_content_div .sub_heading {
        width: 80%;
    }
    .newHomePage_container .smiling_faces_div .users_count_div {
        flex-direction: column;
        gap: 34px;
    }
    .newHomePage_container .smiling_faces_div .users_count_div .happy_count .label {
        font-size: 13px;
    }
    .newHomePage_container .last_inwards_div .FAQ_Section .faqs_section .faq_ques_ans_div .faq_ques_div .faq_ques {
        font-size: 13px;
        text-align: left;
    }
    .newHomePage_container .last_inwards_div .FAQ_Section .faqs_section .faq_ques_ans_div .faq_ques_div .down_vector_faq {
        height: 7px;
    }
    .newHomePage_container .last_inwards_div .FAQ_Section .faqs_section .faq_ques_ans_div .faq_ans {
        color: rgba(0, 0, 0, 0.6);
        font-size: 13px;
    }
    .newHomePage_container .last_inwards_div .FAQ_Section .haveQues_div .haveQ_left_content_div .haveQ_haeding {
        font-size: 25px;
    }
    .newHomePage_container .last_inwards_div .FAQ_Section .haveQues_div .haveQ_left_content_div .haveQ_desc {
        font-size: 13px;
    }
    .newHomePage_container .last_inwards_div .FAQ_Section .haveQues_div .haveQ_left_content_div .contact_btn {
        font-weight: 600;
    }
    .newHomePage_container .last_inwards_div .FAQ_Section .haveQues_div {
        padding-right: 2em;
    }
}
@media (max-width : "450px") {
    .newHomePage_container .top_carousal_bannner_container .carousel-content .left_carousal_content_div {
        width: 100%;
    }
    .newHomePage_container .top_carousal_bannner_container .carousel-content .left_carousal_content_div .sub_heading {
        width: 100%;
    }
    .newHomePage_container .top_carousal_bannner_container .carousel-content .left_carousal_content_div .apply_now_btn {
        width: 100%;
    }
    .newHomePage_container .top_carousal_bannner_container .carousel-content .left_carousal_content_div .tick_div {
        flex-wrap: wrap;
    }
    .newHomePage_container .top_carousal_bannner_container .carousel-content .left_carousal_content_div .best_credit_line_text {
        padding: 10px 20px;
    }
    .newHomePage_container .top_carousal_bannner_container {
        margin-bottom: 0px;
    }
    .newHomePage_container .ourProductsNBenefits_container .ourProducts_cards .product_card {
        width: 100%;
    }
    .newHomePage_container .ourProductsNBenefits_container .ourProducts_cards .product_card {
        padding: 20px 20px;
    }
    .newHomePage_container .ourProductsNBenefits_container .ourProducts_heading {
        font-size: 30px;
    }
    .newHomePage_container .ourProductsNBenefits_container .ourProducts_heading .ourProducts_subHeading {
        font-size: 14px;
    }
    .newHomePage_container .ourProductsNBenefits_container .benefits_heading {
        font-size: 19px;
    }
    .newHomePage_container .ourProductsNBenefits_container .benefits_items .benefit_item .benefit_head {
        font-size: 14px;
        text-align: center;
    }
    .newHomePage_container .ourProductsNBenefits_container .benefits_items .benefit_item .benefit_desc {
        font-size: 11px;
    }
    .newHomePage_container .ourProductsNBenefits_container {
        gap: 34px;
    }
    .newHomePage_container .ourProductsNBenefits_container .apply_now_btn {
        width: 100%;
        padding: 9px 30px;
    }
    .newHomePage_container .apply_know_divs {
        padding: 0px 1em 0px 1em;
    }
    .newHomePage_container .apply_know_divs .apply_div .apply_now_btn {
        width: 100%;
    }
    .newHomePage_container .apply_know_divs .know_credit_div .chart {
        height: 140px;
        width: 259.998px;
    }
    .newHomePage_container .apply_know_divs .know_credit_div .know_left_content .know_heading {
        font-size: 21px;
    }
    .newHomePage_container .smiling_faces_div .smiling_heading {
        font-size: 18px;
    }
    .newHomePage_container .last_inwards_div .ready_div .left_ready_div .ready_heading {
        font-size: 22px;
    }
    .newHomePage_container .last_inwards_div .ready_div .left_ready_div .ready_desc {
        font-size: 13px;
    }
    .newHomePage_container .last_inwards_div .ready_div {
        padding: 38px 20px;
    }
    .newHomePage_container .last_inwards_div .ready_div .left_ready_div .stores_div .store_img {
        height: 40px;
    }
    .newHomePage_container .last_inwards_div .home_blog_div .blogs_cards_div .blog_card {
        width: 100%;
    }
    .newHomePage_container .last_inwards_div .home_blog_div .blogs_cards_div .blog_card .blog_image {
        width: 100%;
    }
    .newHomePage_container .last_inwards_div .home_blog_div .blogs_cards_div {
        gap: 41px;
    }
    .newHomePage_container .last_inwards_div .home_blog_div .blog_head_desc_div .blogs_heading {
        font-size: 23px;
    }
    .newHomePage_container .last_inwards_div .FAQ_Section .haveQues_div .haveQ_left_content_div .haveQ_haeding {
        font-size: 21px;
    }
    .newHomePage_container .ourProductsNBenefits_container .stores_div .store_img {
        width: 141.911px;
        height: 41px;
    }
    .newHomePage_container .apply_know_divs .apply_div .heading_sub_div .heading {
        font-size: 26px;
    }
}

@media (max-width : "368px") {
    .newHomePage_container .top_carousal_bannner_container .carousel-content .left_carousal_content_div .heading_div .heading {
        font-size: 26px;
    }
    .newHomePage_container .apply_know_divs .know_credit_div .know_left_content .know_heading {
        font-size: 18px;
    }
    .newHomePage_container .last_inwards_div .home_blog_div .blogs_cards_div .blog_card .blog_image {
        height: 12em;
    }
}
@media (max-width : "328px") {
    .newHomePage_container .top_carousal_bannner_container .carousel-content .left_carousal_content_div .heading_div .heading {
        font-size: 24px;
    }
}
@media (max-width : "310px") {
    .newHomePage_container .top_carousal_bannner_container .carousel-content .left_carousal_content_div .heading_div .heading {
        font-size: 22px;
    }
}
@media (max-width : "280px") {
    .newHomePage_container .top_carousal_bannner_container .carousel-content .left_carousal_content_div .heading_div .heading {
        font-size: 20px;
    }
}