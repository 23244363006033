.bottomOverlay {
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); /* Semi-transparent black overlay */
    justify-content: center;
    align-items: center;
    z-index: 99;
  }
  .bottomsheet {
    background-color: white;
    padding: 20px 20px 60px 20px;
    border-radius: 23px 23px 0 0;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    min-width: 40%;
    // max-height: 75vh;
    overflow: auto !important;
    position: absolute;
    bottom: 0px;
    width: 100%;
    overflow: hidden;
    height: 63vh;
  }
  
 