.wealthHeader_container{
    display: flex;
    align-items: center;
    padding: 13px;
    background: #ff0028;
    padding-left: 20px;
    justify-content: space-between;
    height: 9vh;
    .header_left{
        display: flex;
        gap: 12px;
        align-items: center;
        .wealthBackBtn{
            height: 19px;
            cursor: pointer;
        }
        .wealthHeading{
            color: white;
            font-size: 17px;
            font-weight: 600;
        }
    }
    .header_right_share_profile_div{
        display: flex;
        align-items: center;
        gap: 20px;
        img{
            height: 29px;
            cursor: pointer;
        }
        .header_right{
            width: 48px;
            height: 48px;
            border-radius: 50%;
            padding: 10px;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: #ccccd8;
            color: #ff0028;
            font-size: 21px;
            font-weight: 600;
        }
    }
    .bottomsheet{
        height: fit-content !important;
        padding: 20px 20px 20px 20px !important;
        .referral_popup{
            display: flex;
            flex-direction: column;
            gap: 17px;
            .cross_div{
                display: flex;
                justify-content: flex-end;
                img{
                    height: 30px;
                }
            }
            .refer_bottom_div{
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: 20px;
                .share_colored{
                    height: 4em;
                }
                .refer_heading{
                    color: #000000;
                    font-size: 22px;
                    font-weight: 600;
                }
                .refer_points{
                    display: flex;
                    flex-direction: column;
                    gap: 28px;
                    padding: 21px;
                    border-radius: 10px;
                    background-color: rgba(0, 0, 102, 0.05);
                    .refer_point{
                        display: flex;
                        align-items: center;
                        gap: 15px;
                        .refer_point_img{
                            height: 4em;
                        }
                        .refer_point_text{
                            font-size: 15px;
                            color: rgba(0, 0, 0, 0.8);
                        }
                    }
                }
                .share_code_text{
                    font-size: 15px;
                    color: rgba(0, 0, 0, 0.8);
                }
                .dotted_code_div{
                    display: flex;
                    justify-content: space-between;
                    width: 100%;
                    align-items: center;
                    background: rgba(0, 0, 102, 0.05);
                    padding: 10px 20px;
                    border-radius: 34px;
                    border: 1px dashed rgba(0, 0, 102, 0.25);
                    .refer_code{
                        color: #000000;
                        font-size: 20px;
                        font-weight: 600;
                    }
                    .copy_img{
                        height: 26px;
                    }
                }
                .share_now_btn{
                    width: 100%;
                    background: #ff0028;
                    color: white;
                    font-size: 18px;
                    font-weight: 500;
                    padding: 13px;
                    border-radius: 31px;
                }
            }
        }
    }
}
.mehroon{
    background: #AA013F;
}
@media (max-width : "360px"){
    .wealthHeader_container .bottomsheet .referral_popup .refer_bottom_div .share_colored {
        height: 3em;
    }
    .wealthHeader_container .bottomsheet .referral_popup .refer_bottom_div .refer_heading {
        font-size: 20px;
    }
    .wealthHeader_container .bottomsheet .referral_popup .refer_bottom_div .refer_points .refer_point .refer_point_img {
        height: 3em;
    }
    .wealthHeader_container .bottomsheet .referral_popup .refer_bottom_div .refer_points .refer_point .refer_point_text {
        font-size: 12px;
    }
    .wealthHeader_container .bottomsheet .referral_popup .refer_bottom_div .dotted_code_div .refer_code {
        font-size: 18px;
    }
    .wealthHeader_container .bottomsheet .referral_popup .refer_bottom_div .dotted_code_div .copy_img {
        height: 23px;
    }
    .wealthHeader_container .bottomsheet .referral_popup .refer_bottom_div .share_now_btn {
        font-size: 16px;
    }
    .wealthHeader_container .bottomsheet .referral_popup .refer_bottom_div .refer_points {
        border-radius: 19px;
    }
}